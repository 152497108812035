<template>
  <v-row>
    <v-col
      cols="0"
      md="8"
      sm="6"
      lg="9"
      xl="10"
      class="pt-0"
    >
      <session-title
        class="pt-8 px-10 pb-5"
        title="Compliance"
      />
    </v-col>
    <v-col
      cols="12"
      md="4"
      sm="6"
      lg="3"
      xl="2"
      class="pb-10 pr-sm-10 pl-15"
      align-self="center"
    >
      <chat-ti-modal-access-vue />
    </v-col>
    <v-col>
      <compliance-grid />
    </v-col>
  </v-row>
</template>

<script>
import SessionTitle from '@/components/SessionTitle'
import ComplianceGrid from '@/components/ComplianceGrid'
import ChatTiModalAccessVue from '../../components/ModalViews/ChatTiModalAccess.vue'

export default {
  name: 'Compliance',
  components: {
    'session-title': SessionTitle,
    'compliance-grid': ComplianceGrid,
     ChatTiModalAccessVue,
  }
}
</script>
